<template>
  <div class="quill">
    <!--<div :id="toolbarId">
      <div class="ql-formats">
        <button class="ql-bold"></button>
        <button class="ql-italic"></button>
        <button class="ql-underline"></button>
        <button class="ql-link"></button>
        <button class="ql-blockquote"></button>
        <button class="ql-code"></button>
        <button class="ql-image"></button>
        <button type="button" class="ql-list" value="ordered"></button>
        <button type="button" class="ql-list" value="bullet"></button>
      </div>
    </div>-->
    <div :id="editorId" :name="name" class="" ref="editor"></div>
  </div>
</template>
<script>
import Quill from 'quill';
import 'quill/dist/quill.snow.css'; // Assurez-vous d'inclure le CSS de Quill
export default {
  name: "html-editor",
  props: {
    value: {
      type: String,
      default: "",
    },
    name: String,
  },
  data() {
    return {
      editor: null,
      content: null,
      lastHtmlValue: "",
      editorId: null,
      toolbarId: null,
    };
  },
  methods: {
    initialize(Quill) {
      const toolbarOptions = [
        [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
        [{ 'list': 'ordered' }, { 'list': 'bullet' }],
        [{ 'align': [] }],
        ['bold', 'italic', 'underline'],
        [{ 'color': [] }, { 'background': [] }], // Option de couleur
        ['link'],
        [{ 'indent': '-1' }, { 'indent': '+1' }],
        ['blockquote'],
        [{ 'direction': 'rtl' }],
        ['image'],
        ['clean'] // Bouton pour effacer la mise en forme
      ];
      this.editor = new Quill(`#${this.editorId}`, {
        theme: "snow",
        modules: {
          toolbar: toolbarOptions,
        },
      });

      if (this.value.length > 0) {
        this.editor.pasteHTML(this.value);
      }

      let editorRef = this.$refs.editor;
      let node = editorRef.children[0];
      this.editor.on("text-change", () => {
        let html = node.innerHTML;
        if (html === "<p><br></p>") {
          html = "";
        }
        this.content = html;
        this.$emit("input", this.content);
      });
    },
    pasteHTML() {
      if (!this.editor) {
        return;
      }
      this.editor.pasteHTML(this.value);
    },
    randomString() {
      let text = "";
      let possible = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz";

      for (let i = 0; i < 5; i++)
        text += possible.charAt(Math.floor(Math.random() * possible.length));

      return text;
    },
  },
  async mounted() {
    /*const toolbarOptions = [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }],
      [{ 'align': [] }],
      ['bold', 'italic', 'underline'],
      [{ 'color': [] }, { 'background': [] }], // Option de couleur
      ['link'],
      [{ 'indent': '-1' }, { 'indent': '+1' }],
      ['blockquote'],
      [{ 'direction': 'rtl' }],
      ['image'],
      ['clean'] // Bouton pour effacer la mise en forme
    ];*/

    /*const quill = new Quill(this.$refs.editor, {
      theme: 'snow',
      modules: {
        toolbar: toolbarOptions,
      },
    });*/
    let Quill = await import("quill");
    Quill = Quill.default || Quill;
    this.editorId = this.randomString();
    this.toolbarId = this.randomString();
    this.$nextTick(() => {
      this.initialize(Quill);
    });
  },
  watch: {
    value(newVal) {
      if (newVal !== this.content) {
        this.pasteHTML(newVal);
      }
    },
  },
};
</script>
